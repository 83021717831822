@import "../theme/mixins";

$brand: 'table_dot';
$brandAssets: "/assets/#{$brand}";

@font-face {
  font-family: AppFont;
  src: url("../assets/fonts/HankenGrotesk-Regular.ttf");
}
@font-face {
  font-family: AppFontMedium;
  src: url("../assets/fonts/HankenGrotesk-Medium.ttf");
}
@font-face {
  font-family: AppFontBold;
  src: url("../assets/fonts/HankenGrotesk-Bold.ttf");
}
@font-face {
  font-family: AppFontExtraBold;
  src: url("../assets/fonts/HankenGrotesk-ExtraBold.ttf");
}
@font-face {
  font-family: AppFontSemiBold;
  src: url("../assets/fonts/HankenGrotesk-SemiBold.ttf");
}
@font-face {
  font-family: SecondaryFont;
  src: url("../assets/fonts/Cabin-Regular.ttf");
}
@font-face {
  font-family: SecondaryFontBold;
  src: url("../assets/fonts/Cabin-Bold.ttf");
}
@font-face {
  font-family: SecondaryFontMedium;
  src: url("../assets/fonts/Cabin-Medium.ttf");
}
@font-face {
  font-family: SecondaryFontSemiBold;
  src: url("../assets/fonts/Cabin-SemiBold.ttf");
}
@font-face {
  font-family: TertiaryFontMedium;
  src: url("../assets/fonts/Bitter-Medium.ttf");
} 
:root {
  --brand: $brand;
  --ion-color-primary: #28373C;
	--ion-color-primary-rgb: 40,55,60;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #233035;
	--ion-color-primary-tint: #3e4b50;

	--ion-color-secondary: #FABE5F;
	--ion-color-secondary-rgb: 250,190,95;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #dca754;
	--ion-color-secondary-tint: #fbc56f;

  --ion-color-tertiary: #91A58C;
	--ion-color-tertiary-rgb: 145,165,140;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #80917b;
	--ion-color-tertiary-tint: #9cae98;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #66605a;
  --ion-color-dark-rgb: 102,96,90;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #5a544f;
  --ion-color-dark-tint: #75706b;

  --ion-color-medium: #393939;
	--ion-color-medium-rgb: 57,57,57;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #323232;
	--ion-color-medium-tint: #4d4d4d;


  --ion-color-light: #B0B0B0;
	--ion-color-light-rgb: 176,176,176;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #9b9b9b;
	--ion-color-light-tint: #b8b8b8;

  --ion-font-family: AppFont, sans-serif;
  --ion-margin: 12px;
  --ion-padding: 12px;
  --ion-color-title: #28373C;
  --ion-color-category: #F7F5EB;


  --ion-background-color: #F7F5EB;
}

h1 {
  font-family: AppFontBold, Helvetica, sans-serif;
}

.ion-page,
ion-footer,
ion-content {
  background: white;
  --background: white;
}
app-menu-modal {
  // background: var(--ion-color-primary) url(#{$brandAssets}/menu-modal.svg) no-repeat right bottom / 50% !important;

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-payment-modal {
  // background: white url(#{$brandAssets}/activity_category_image.svg) no-repeat right bottom / 50% !important;
  // --background: white url(#{$brandAssets}/activity_category_image.svg) no-repeat right bottom / 50% !important;

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-payment-modal,
app-payment-success {
  background: var(--ion-background-color) !important;
  --background: var(--ion-background-color) !important;

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-home {
  background:  var(--ion-color-primary) url(#{$brandAssets}/image-home.png) no-repeat center top / 100% !important;
  background-size: cover !important;

  @include mobile {
    // background: var(--ion-color-primary) url(#{$brandAssets}/home_background_mobile.png) no-repeat center top / 100% !important;
  }

  ion-header {
    background: transparent;
  }

  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
  ion-footer {
    background: transparent !important;
    --background: transparent !important;
  }
}

